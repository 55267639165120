import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { withPrefix } from 'gatsby'

const pdfStyle = {
  width: '100%',
  height: '80vh',
}

const Employment = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Net Price Calculator</title>
      <meta name="description" content="Net Price Calculator" />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Net Price Calculator</h1>
          </header>
          <iframe
            src={withPrefix('/npcalc.htm')}
            width="100%"
            height="100%"
            style={pdfStyle}
            title="Net Price Calculator"
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Employment
